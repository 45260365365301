export default {
  type: "msspline",
  chart: {
    caption: "System Activities",
    compactdatamode: "1",
    pyaxisname: "No. of Items",
    syaxisname: "No. of pickups",
    setadaptiveymin: "1",
    scrollheight: "10",
    numvisibleplot: "10",
    linethickness: "2",
    theme: "fusion",
    labeldisplay: "rotate",
    rotatelabels: "1"
  },
  data: [],
  categories: [
    {
      category: [
        {label: ''}
      ]
    }
  ],
  dataset: [
    {
      seriesname: 'No. of Items',
      data: []
    },
    {
      seriesname: 'No. of pickups',
      data: []
    }
  ]
}