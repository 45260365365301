<template>
  <v-main>
    <AppBar />
    <Loading v-model="isLoading" />
    <v-container>
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <v-select
            v-model="location"
            :items="locations"
            ref="locationList"
            label="Location"
            filled
            background-color="#fff"
            class="elevation-2 bg-white"
            hide-details="auto"
            item-text="name"
            item-value="id"
            @change="onLocationChange"
            return-object
          ></v-select>
        </v-col>
      </v-row>



      <v-row>
        <v-col>
          <v-card>
            <v-card-text>locations<br>{{global.locations}}</v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-card>
            <v-card-text>units<br>{{global.units}}</v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-card>
            <v-card-text>notifications<br>{{global.notifications}}</v-card-text>
          </v-card>
        </v-col>

      </v-row>

      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3 class="text-left">Today <span style="font-size: 60%;"> Current Date &amp; Time: {{time}}</span></h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text>processed<br>{{today.processed}}</v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-card>
            <v-card-text>pickups<br>{{today.pickups}}</v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-card>
            <v-card-text>notifications<br>{{today.notifications}}</v-card-text>
          </v-card>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12" md="6" sm="12">
          <v-card>
            <v-card-text>
              <fusioncharts
                :type="todayProcessedDataSource.type"
                width="100%"
                :height="400"
                :dataFormat="dataFormat"
                :dataSource="todayProcessedDataSource"
              ></fusioncharts>

            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-card>
            <v-card-text>
              <fusioncharts
                :type="todayPickupsDataSource.type"
                width="100%"
                :height="400"
                :dataFormat="dataFormat"
                :dataSource="todayPickupsDataSource"
              ></fusioncharts>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="4" sm="12">
          <v-card>
            <v-card-text>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dateRange"
            transition="scale-transition"
            offset-y
            min-width="auto"
            @input="onDateRangeChange"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                
                v-model="dateRangeText"
                label="Date Range"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange"
              no-title
              scrollable
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(dateRange) && onDateRangeChange()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
            </v-card-text>
          </v-card>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <fusioncharts
                :type="onhandDataSource.type"
                width="100%"
                :height="400"
                :dataFormat="dataFormat"
                :dataSource="onhandDataSource"
              ></fusioncharts>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3 class="text-left">Trends</h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="6">
            <v-card>
                <v-card-text>
                  <div id="chart1">
                    <fusioncharts
                      :type="itemsWeekTrends.type"
                      :dataFormat="dataFormat"
                      width="100%"
                      :dataSource="itemsWeekTrends"
                      ref="chart1"
                    >
                    </fusioncharts>
                  </div>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="6">
            <v-card>
                <v-card-text>
                  <div id="chart2">
                    <fusioncharts
                      :type="pickupsWeekTrends.type"
                      :dataFormat="dataFormat"
                      width="100%"
                      :dataSource="pickupsWeekTrends"
                      ref="chart2"
                    >
                    </fusioncharts>
                  </div>
                </v-card-text>
            </v-card>
        </v-col>                            
      </v-row>


      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <fusioncharts
                :type="trendsDataSource.type"
                width="100%"
                :height="400"
                :dataFormat="dataFormat"
                :dataSource="trendsDataSource"
              ></fusioncharts>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>



    </v-container>
  </v-main>
</template>

<script>

import AppBar from '@/components/general/AppBar.vue'
import todayProcessedDataSource from '../../charts/todayProcessed'
import todayPickupsDataSource from '../../charts/todayPickups'
import onhandDataSource from '../../charts/onhand'
import trendsDataSource from '../../charts/trends'
import itemsWeekTrends from '../../charts/itemsWeekTrends'
import pickupsWeekTrends from '../../charts/pickupsWeekTrends'
import Loading from '../../components/Loading.vue'
export default {
  name: 'Overview',

  components: {
    AppBar,
    Loading
  },

  data:() => ({
    loading: 0,
    locations: [],
    location: {
      name: '',
      id: -1
    },
    global: {
      locations: null,
      units: null,
      notifications: null
    },
    today: {
      processed: null,
      pickups: null,
      notifications: null
    },
    dataFormat: "json",
    todayProcessedDataSource,
    todayPickupsDataSource,
    onhandDataSource,
    trendsDataSource,
    dateRange: [],
    menu: false,
    interval: null,
    time: null,
    itemsWeekTrends,
    pickupsWeekTrends
  }),

  computed: {
    isLoading() {
      return this.loading == 0 ? false : true
    },
    dateRangeText () {
        return this.dateRange.join(' to ')
    },
  },

  mounted() {
    console.log('overview')
    this.loadLocations()
    // this.loadGlobal(true)
    // get current week start and end
    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(curr.setDate(first))
    var lastday = new Date(curr.setDate(last))

    firstday = firstday.toISOString().split('T')[0]
    lastday = lastday.toISOString().split('T')[0]
    this.dateRange.push(firstday)
    this.dateRange.push(lastday)

    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric',
        // second: 'numeric',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }).format()
    }, 1000)
    // this.onLocationChange()
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },  

  methods: {
    setDateRange(start) {
      let currentDate = new Date()
      let end = currentDate.toISOString().split('T')[0]
      this.dateRange = [start, end]
    },
    onDateRangeChange() {
      if (!this.location) return
      this.loadOnhandItems()
      this.loadTrends()
      this.loadToday()
    },
    async onLocationChange() {
      await this.loadGlobal()
      this.loadTodayProcessedChart(this.location)
      this.loadTodayPickupsChart(this.location)
      this.loadOnhandItems()
      this.loadTrends()
      this.loadToday()
      this.loadItemsWeekTrend()
      this.loadPickupsWeekTrend()
      
    },
    loadToday() {
      // this.loading++
      let isFirst = typeof isFirst === 'undefined' ? false : isFirst
      this.loading++
      let obj
      if (this.location.isOrg) {
        obj = {org_id: this.location.id}
      }
      if (this.location.isLocation) {
        obj = {location_id: this.location.id}
      }
      if (this.location.isGlobal) {
        obj = {isGlobal: 1, isFirst}
      }

      this.$api.report.today({obj})
        .then(data => {
          this.today = data
        })
        .finally(() => {
          this.loading--
        })
    },
    loadLocations() {
      this.loading++
      this.$api.location.all()
        .then(data => {
          const items = []
          for(let i=0;i<data.length;i++) {
            items.push({
              name: data[i].name + ' - All',
              id: data[i].id,
              isOrg: true,
              isLocation: false,
              isGlobal: false
            })
            for(let j=0;j<data[i].active_locations.length;j++) {
              items.push({
                name: data[i].name + ' - ' + data[i].active_locations[j].name,
                id: data[i].active_locations[j].id,
                isOrg: false,
                isLocation: true,
                isGlobal: false
              })
            }
          }

          items.push({
            name: 'Global',
            id: null,
            isOrg: false,
            isLocation:false,
            isGlobal: true
          })

          items.push({
            name: '',
            id: -1
          });

          // data.map(l => l.name = l.organization.name + ' - ' + l.name)
          // data.sort((item1, item2) => {
          //     return item1.name.toLowerCase() < item2.name.toLowerCase() ? -1 : 1
          // })
          this.locations = items
        })
        .finally(() => {
          this.loading--
        })
    },
    async loadGlobal(isFirst) {
      return new Promise((resolve, reject) => {
        isFirst = typeof isFirst === 'undefined' ? false : isFirst
        this.loading++
        let obj
        if (this.location.isOrg) {
          obj = {org_id: this.location.id}
        }
        if (this.location.isLocation) {
          obj = {location_id: this.location.id}
        }
        if (this.location.isGlobal) {
          obj = {isGlobal: 1, isFirst}
        }

        this.$api.report.global(obj)
          .then(data => {
            this.global = data
            this.setDateRange(data.startDate)
            resolve(true)
          })
          .catch(() => {
            reject(false)
          })
          .finally(() => {
            this.loading--
          })
      })

    },
    loadPickupsWeekTrend() {
      this.loading++
      let obj
      if (this.location.isOrg) {
        obj = {org_id: this.location.id}
      } else if(this.location.isLocation) {
        obj = {location_id: this.location.id}
      } else {
        obj = {isGlobal: true}
      }

      this.$api.report.pickupsWeekTrend(obj)
        .then(data => {
          console.log(data)

					for(let i=0;i<= data.length-1;i++) {
            this.pickupsWeekTrends.dataset[0].data.push({
              value: data[i].count_date,
              showValue: data[i].count_date == 0 ? 0 : 1
            })
            this.pickupsWeekTrends.dataset[1].data.push({
              value: data[i].avg_day
            })
					}

        })
        .finally(() => {
          this.loading--
        })

    },
    loadItemsWeekTrend() {
      this.loading++
      let obj
      if (this.location.isOrg) {
        obj = {org_id: this.location.id}
      } else if(this.location.isLocation) {
        obj = {location_id: this.location.id}
      } else {
        obj = {isGlobal: true}
      }

      this.$api.report.itemsWeekTrend(obj)
        .then(data => {
          console.log(data)

					for(let i=0;i<= data.length-1;i++) {
            this.itemsWeekTrends.dataset[0].data.push({
              value: data[i].count_date,
              showValue: data[i].count_date == 0 ? 0 : 1
            })
            this.itemsWeekTrends.dataset[1].data.push({
              value: data[i].avg_day
            })
					}

        })
        .finally(() => {
          this.loading--
        })

    },
    loadTodayProcessedChart() {
      this.loading++
      let obj
      if (this.location.isOrg) {
        obj = {org_id: this.location.id}
      } else if(this.location.isLocation) {
        obj = {location_id: this.location.id}
      } else {
        obj = {isGlobal: true}
      }
      this.$api.report.todayProcessed(obj)
        .then(data => {
          this.todayProcessedDataSource.dataset[0].data = data.processed
          this.todayProcessedDataSource.dataset[1].data = data.average
        })
        .finally(() => {
          this.loading--
        })
    },
    loadTodayPickupsChart() {
      this.loading++
      let obj
      if (this.location.isOrg) {
        obj = {org_id: this.location.id}
      } else if(this.location.isLocation) {
        obj = {location_id: this.location.id}
      } else {
        obj = {isGlobal: true}
      }
      this.$api.report.todayPickups(obj)
        .then(data => {
          this.todayPickupsDataSource.dataset[0].data = data.processed
          this.todayPickupsDataSource.dataset[1].data = data.average
        })
        .finally(() => {
          this.loading--
        })
    },
    loadOnhandItems() {
      if (!this.location) return
      if (typeof this.dateRange[0] === 'undefined') return
      if (typeof this.dateRange[1] === 'undefined') return
      this.loading++
      let obj
      if (this.location.isOrg) {
        obj = {
          org_id: this.location.id,
          start: this.dateRange[0], 
          end: this.dateRange[1]
        }
      } else if(this.location.isLocation) {
        obj = {
          location_id: this.location.id,
          start: this.dateRange[0], 
          end: this.dateRange[1]
        }
      } else {
          obj = {
            isGlobal: true,
            start: this.dateRange[0], 
            end: this.dateRange[1]
          }
      }

      this.$api.report.onhand(obj)
        .then(data => {
          // let previous = data.onhand.previous
          const entries = data.onhand
          // for (let i=0;i<entries.length;i++) {
          //   entries[i].value = parseInt(entries[i].value) + parseInt(previous)
          //   previous = entries[i].value
          // }
          console.log('entries', entries)
          this.onhandDataSource.data = entries
          // console.log(data)
        })
        .finally(() => {
          this.loading--
        })
    },
    loadTrends() {
      if (!this.location) return
      if (typeof this.dateRange[0] === 'undefined') return
      if (typeof this.dateRange[1] === 'undefined') return
      this.loading++
      let obj
      if (this.location.isOrg) {
        obj = {
          org_id: this.location.id,
          start: this.dateRange[0], 
          end: this.dateRange[1]
        }
      } else if(this.location.isLocation) {
        obj = {
          location_id: this.location.id,
          start: this.dateRange[0], 
          end: this.dateRange[1]
        }
      } else {
          obj = {
            isGlobal: true,
            start: this.dateRange[0], 
            end: this.dateRange[1]
          }
      }
      this.$api.report.trends(obj)
        .then(data => {
          this.trendsDataSource.categories[0].category = data.items
          this.trendsDataSource.dataset[0].data = data.items
          this.trendsDataSource.dataset[1].data = data.pickups
          console.log(data)
        })
        .finally(() => {
          this.loading--
        })
    }


  }
}
</script>
