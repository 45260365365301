export default {
  type: "mscombi2d",
  chart: {
    "caption": "Pickups",
    "subCaption": "",
    "xAxisname": "Day of the Week",
    "yAxisName": "No. of Pickups",
    "numberPrefix": "",
    "divlineColor": "#999999",
    "divLineIsDashed": "1",
    "divLineDashLen": "1",
    "divLineGapLen": "1",
    "toolTipColor": "#ffffff",
    "toolTipBorderThickness": "0",
    "toolTipBgColor": "#000000",
    "toolTipBgAlpha": "80",
    "toolTipBorderRadius": "2",
    "toolTipPadding": "5",
    "theme": "fusion"          
  },
  categories: [
    {
      category: [
        {label: 'Sun'},
        {label: 'Mon'},
        {label: 'Tue'},
        {label: 'Wed'},
        {label: 'Thu'},
        {label: 'Fri'},
        {label: 'Sat'},
      ]
    }
  ],
  dataset: [
    {
      seriesName: 'No. of Pickups',
      showValues: "1",
      data: []
    },
    {
      seriesName: 'Trend',
      renderAs: 'line',
      data: []
    }
  ]
}