export default {
  type: 'splinearea',
  chart: {
    caption: "Mail On-Hand",
    subcaption: "",
    showvalues: "0",
    flatscrollbars: "0",
    scrollheight: "12",
    scrollshowbuttons: "1",
    numvisibleplot: "6",
    theme: "fusion",
    labeldisplay: "rotate",
    rotatelabels: "1"
  },
  data: []
}