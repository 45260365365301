export default {
  type: 'scrollcombidy2d',
  chart: {
    caption: "Pickups",
    subcaption: "",
    yaxisname: "No. of Pickups",
    // syaxisname: "Subsidies % of Income",
    labeldisplay: "rotate",
    // snumbersuffix: "%",
    scrollheight: "10",
    numvisibleplot: "10",
    drawcrossline: "1",
    theme: "fusion"
  },    
  categories: [
    {
      category: [
        {label: '12AM'},
        {label: '1AM'},
        {label: '2AM'},
        {label: '3AM'},
        {label: '4AM'},
        {label: '5AM'},
        {label: '6AM'},
        {label: '7AM'},
        {label: '8AM'},
        {label: '9AM'},
        {label: '10AM'},
        {label: '11AM'},
        {label: '12PM'},
        {label: '1PM'},
        {label: '2PM'},
        {label: '3PM'},
        {label: '4PM'},
        {label: '5PM'},
        {label: '6PM'},
        {label: '7PM'},
        {label: '8PM'},
        {label: '9PM'},
        {label: '10PM'},
        {label: '11PM'},
      ]
    }
  ],
  dataset: [
    {
      seriesname: 'No. of Pickups',
      plottooltext: "No. of Pickups: $dataValue",
      data: [

      ]
    },
    {
      seriesname: "Average",
      parentyaxis: "S",
      renderas: "line",
      plottooltext: "Average: $dataValue",
      showvalues: "0",         
      data: []
    }
  ]
}